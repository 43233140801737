import request from '@/plugins/axios'

export function getshoppinglist(params) {
  // 获取商品列表
  return request({
    url: 'service/goods',
    method: 'get',
    params
  })
}

export function getshoppingMenu(params) {
  // 获取商品分级数据
  return request({
    url: 'service/goods/categorys',
    method: 'get',
    params
  })
}

export function editshopping(id, data) {
  // 修改商品信息
  return request({
    url: `service/goods/${id}`,
    method: 'patch',
    data
  })
}

export function addshopping(data) {
  // 添加商品
  return request({
    url: 'service/goods',
    method: 'post',
    data
  })
}

export function addOrders(goodsId, amount, url, remark) {
  // 生成订单
  return request({
    url: 'service/orders',
    method: 'post',
    data: {
      goods: goodsId,
      amount,
      url,
      remark
    }
  })
}

export function getOrderList(params) {
  // 获取订单列表
  return request({
    url: 'service/orders',
    method: 'get',
    params
  })
}

export function editOrderValue(id, data) {
  // 修改订单
  return request({
    url: `orders/${id}`,
    method: 'put',
    data
  })
}
