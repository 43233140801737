<template>
  <div class="app-container">
    <el-form :inline="true" :model="orderListForm" class="demo-form-inline">
      <el-form-item label="服务ID" style="margin:0 20px 0 50px">
        <el-input v-model="orderListForm.goodsId"></el-input>
      </el-form-item>
      <el-form-item label="订单状态" style="margin:0 20px 0 50px">
        <el-select v-model="orderListForm.state" placeholder="请选择订单状态">
          <el-option label="已提交" value="0"></el-option>
          <el-option label="处理中" value="2"></el-option>
          <el-option label="已完成" value="1"></el-option>
          <el-option label="已关闭" value="-1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="orderForm"
      style="width: 100%"
      v-loading="listLoading"
      border
      fit
      highlight-current-row
      class="tableList2"
    >
      <el-table-column prop="id" label="ID" width="80px"></el-table-column>
      <el-table-column
        prop="goods_entity.id"
        label="服务ID"
        width="80px"
      ></el-table-column>
      <el-table-column
        prop="goods_entity.platform"
        label="服务平台"
        width="100px"
      ></el-table-column>
      <el-table-column
        prop="goods_entity.category"
        label="服务类别"
        width="100px"
      ></el-table-column>
      <el-table-column
        prop="goods_entity.name"
        label="服务标题"
        width="280px"
      ></el-table-column>

      <el-table-column
        prop="goods_entity.price"
        label="服务价格"
      ></el-table-column>
      <el-table-column prop="amount" label="购买数量"></el-table-column>
      <el-table-column prop="spend" label="积分花费"></el-table-column>

      <el-table-column prop="state" label="订单状态" width="80px">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.state === 0">已提交</el-tag>
          <el-tag v-else-if="scope.row.state === 2" type="warning">
            处理中
          </el-tag>
          <el-tag v-else-if="scope.row.state === 1" type="success">
            已完成
          </el-tag>
          <el-tag v-else type="danger">已关闭</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="url" label="链接"></el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        width="250px"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        width="160px"
      ></el-table-column>
    </el-table>

    <div class="block" style="marginTop:10px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageIndex"
        :page-size="pageSize"
        :page-sizes="[pageSize, 20, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getOrderList } from '@/api/shopping.js'
// import editOrder from '@/components/order/editOrder'

export default {
  // components: { editOrder },
  data() {
    return {
      orderForm: [],
      listLoading: true,
      showOrderTitle: null,
      showOrderAssign: false,
      orderValue: null,
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      orderListForm: {}
    }
  },
  created() {
    this.orderList()
  },
  methods: {
    // 获取订单列表
    orderList() {
      this.listLoading = true
      getOrderList({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        goods: this.orderListForm.goodsId,
        state: this.orderListForm.state
      }).then(resp => {
        this.orderForm = resp.items
        this.total = resp.all_count
        this.listLoading = false
      })
    },
    // 关闭弹窗
    close(data) {
      this.showOrderAssign = data
      this.orderList()
    },
    handleSizeChange(val) {
      // 当每页显示条数发生变化时
      this.pageSize = val
      this.orderList()
    },
    handleCurrentChange(val) {
      // 当页码发生变化时
      this.pageIndex = val
      this.orderList()
    },
    onSubmit() {
      console.log('submit')
      this.orderList()
    },
    // 重置
    resetForm() {
      this.orderListForm.userId = ''
      this.orderListForm.goodsId = ''
      this.orderListForm.state = ''
      this.orderList()
    }
  }
}
</script>

<style></style>
